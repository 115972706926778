button {
    font-weight: bold;
    font-size: inherit;
    font-family: din-2014, Barlow, sans-serif;
    border-radius: var(--borderRadius);
    padding: 15px 20px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    color: white;
    border: solid 2px #fff;
}

button a {
    margin: 0;
}

button:hover {
    color: black;
    background-color: white;
    transition: 0.2s;
}