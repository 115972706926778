.bentoStat {
    /*border: var(--border);*/
    /*background-color: var(--tileBackground);*/
    /*backdrop-filter: var(--tileBlur);*/
    /*border-radius: var(--borderRadius);*/
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 180px;
    flex-grow: 1;
    text-transform: uppercase;
    flex-basis: 100%;
    height: 100%;
}

.bentoStat h2 {
    color: #68ada2;
    font-size: 120px;
    line-height: 120px;
}
