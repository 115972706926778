/*INDEX.CSS IS FOR GLOBAL STYLES*/

/*DIN 2014 and Moon typefaces*/

:root {
  /*--baseColor: #0c0f14;*/
  --baseColor: #1e1e1e;
  --text: #fff;
  --subhead: #c8c8c8;
  --textDark: rgba(100, 100, 100, 1);
  --highlight: #68ada2;
  /*--tileBackground: rgba(32, 39, 67, 0.25);*/
  --tileBackground: rgba(50, 50, 50, 1);
  --tileBlur: blur(30px);
  --borderRadius: 10px;
  /*--border: 1px solid rgba(30, 40, 50, 0.8);*/
  --borderNav: 1px solid rgba(40, 40, 40, 1);
  --border: none;
  --headlineWidth: 1100px;
  --contentWidth: 900px;
}

@font-face {
  font-family: "Moon";
  src: local("Moon"),
  url("./fonts/F37Moon-ExtraBold.otf") format("opentype");
  font-weight: bold;
}

/*Reset Styles*/

* {
  box-sizing: border-box;
  /*transition: 1s;*/
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-transform: uppercase;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  color: var(--text);
  background-color: var(--baseColor);
  font-family: din-2014, Barlow, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 107%;
}

/*Global Styles*/

.hero {
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
}

.heroOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: inherit;
  align-items: inherit;
}

.headlineContainer, .pageHeadlineContainer {
  width: var(--headlineWidth);
  margin: 0 auto;
  padding: 50px 0;
}

.contentContainer {
  width: var(--contentWidth);
  margin: 0 auto;
  padding: 50px 0;
}

.pageHeadlineContainer {
  font-size: 200px;
  text-align: center;
}

.pageHeadlineContainer h2 {
  font-size: 100px;
}

.heroTextContainer {
  width: 600px;
}

.heroTextContainer p {
  font-size: 18px;
  margin-bottom: 40px;
}

/*FLEXBOXES*/

.flexContainer {
  display: flex;
}

.bentoContainer {
  display: flex;
  gap: 15px;
}

.bentoStatContainer {
  display: flex;
  flex-grow: 1;
  gap: 15px;
  margin-bottom: 50px;
}

/*TYPE STYLES*/

h1 {
  font-size: 60px;
}

h2 {
  font-family: "Moon", sans-serif;
  font-size: 60px;
  line-height: 60px;
}

h3 {
  font-family: "Moon", sans-serif;
  font-size: 50px;
  margin-bottom: 20px;
}

h4 {
  font-size: 18px;
  color: var(--subhead);
}

h5 {
  font-size: inherit;
  text-transform: uppercase;
  color: var(--textDark);
}

h6 {
  font-size: inherit;
}

figure {
  margin: 0;
  padding: 30px;
  border: solid 3px var(--tileBackground);
  border-radius: 10px;
}

figcaption {
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: right;
  color: var(--textDark);
  font-weight: bold;
}

.infoNote {
  color: var(--highlight);
  font-style: italic;
  font-weight: bold;
}

hr {
  /*border-color: var(--textDark);*/
  border: 1px solid var(--tileBackground);
  border-radius: 5px;
  margin: 50px 0;
}

.highlight {
  font-weight: bold;
  color: var(--highlight);
}

.IMG100 {
  width: 100%;
  border-radius: var(--borderRadius);
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: var(--borderRadius);
}

@media screen and (max-width: 1300px) {
  /*.contentContainer, .headlineContainer {*/
  /*  width: 100%;*/
  /*  padding: 50px 50px;*/
  /*}*/
}

@media screen and (max-width: 1210px) {
/*  .bentoContainer {*/
/*    flex-direction: column-reverse;*/
/*  }*/
/*  .RocketIMG {*/
/*    width: 100%;*/
/*  }*/
/*  .bentoStatContainer {*/
/*    flex-direction: row;*/
/*  }*/
  .contentContainer, .headlineContainer {
    width: 100%;
    padding: 50px 50px;
  }
}

@media screen and (max-width: 900px) {
    .bentoStatContainer {
        flex-direction: column;
    }
    .flexContainer {
        flex-direction: column;
    }
    .pageHeadlineContainer h2 {
        font-size: 80px;
    }
}

@media screen and (max-width: 700px) {
    .headlineContainer {
        width: 100%;
        padding: 50px 50px;
    }
    .heroTextContainer {
      width: 100%;
    }
    .pageHeadlineContainer h2 {
        font-size: 60px;
    }
}

@media screen and (max-width: 400px) {
    h1 {
        font-size: 30px;
    }
    .pageHeadlineContainer h2 {
        font-size: 40px;
    }
    h3 {
        font-family: "Moon", sans-serif;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .headlineContainer {
      padding: 50px 20px;
    }
}