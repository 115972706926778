.splashImage, .topImage {
    position: relative;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
}

.topImage {
    height: 30vh;
    padding-top: 50px;
    flex-direction: initial;
    align-items: center;
}

.backgroundImage {
    object-fit: cover;
}

.backgroundImage, .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.wireframe {
    width: 100%;
    /*margin-bottom: 50px;*/
}

.skills {
    margin: 50px 0;
}