footer {
    background-color: black;
}

.footerContainer {
    width: var(--headlineWidth);
    margin: auto;
}

.footerLogos {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.footerLinks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

footer a {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
}

.RocketTeamLogoWhite {
    height: 40px;
}

.BaskinLogo {
    height: 40px;
    margin-bottom: -4px;
}

.UCSCLogo {
    height: 30px;
    margin-right: 20px;
}

.UCSCLogos {
    display: flex;
    align-items: center;
}

.footerInfo {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.preferences {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1210px) {
    footer {
        padding: 0 50px;
    }
    .footerContainer {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .footerLinks {
        justify-content: normal;
    }
    .footerColumn {
        flex-basis: 33.333%;
        padding-top: 40px;
    }
}

@media screen and (max-width: 850px) {
    .footerColumn {
        flex-basis: 50%;
    }

    .footerInfo {
        flex-direction: column;
        text-align: center;
    }
    .footerLogos {
        display: none;
    }
}