.eventsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.eventsTile {
    border: var(--border);
    background-color: var(--tileBackground);
    backdrop-filter: var(--tileBlur);
    border-radius: var(--borderRadius);
    padding: 10px;
    flex-basis: calc(25% - 10px);
}

.eventsTile p {
    color: grey;
    margin: 0;
}

.eventInfo {
    margin-top: 10px;
}

.eventTitle {
    /*margin-top: 20px;*/
}

@media (max-width: 1000px) {
    .eventsTile {
        flex-basis: calc(50% - 10px);
    }
}

@media (max-width: 600px) {
    .eventsTile {
        flex-basis: calc(100% - 10px);
    }
}
