.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex: 1;
}

.g10 {
    gap: 10px;
}

.g20 {
    gap: 20px;
}

.g30 {
    gap: 30px;
}

.g40 {
    gap: 40px;
}

.bentoImage {
    width: 100%;
    border-radius: var(--borderRadius);
}