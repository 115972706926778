.sideNavigation {
    width: 300px;
    flex-shrink: 0;
}

.sideNavigation h5 {
    font-size: 26px;
    margin-bottom: 20px;
}

.sideNavigation h6 {
    margin: 13px 0;
}

.sideNavigation h6:hover {
    color: var(--highlight);
}

.sideNavigation hr {
    margin: 0;
}

@media screen and (max-width: 900px) {
    .sideNavigation {
        display: none;
    }
}