.leadTile {
    border: var(--border);
    margin-bottom: 30px;
    padding-right: 15px;
}

.bigPhotoFalse {
    width: 130px;
    height: 130px;
    flex-shrink: 0;
}

.bigPhotoTrue {
    width: 250px;
    height: 250px;
    flex-shrink: 0;
    margin-right: 5px;
}

.leadIMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius);
}

.bigFalse {
    display: flex;
    flex-basis: 50%;
}

.leadTile {
    border: var(--border);
    margin-bottom: 30px;
    padding-right: 15px;
    display: flex; /* Ensure Flexbox is applied */
    align-items: center; /* Vertically center the image and textContainer */
}

.leadTile .textContainer {
    flex-grow: 1;
    display: flex; /* Flexbox for internal layout (if needed) */
    flex-direction: column; /* Stack text elements vertically */
    justify-content: center; /* Vertically center the text within the textContainer itself */
}

.leadTile h4 {
    font-size: 22px;
    color: white;
}

.leadTile h5 {
    margin-top: -4px;
    margin-bottom: 10px;
}

.leadTile span {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--subhead);
}

@media screen and (max-width: 900px) {
    .bigPhotoFalse {
        width: 100%;
        height: 250px;
    }
    .bigPhotoTrue {
        width: 100%;
        height: 400px;
    }
}

@media screen and (max-width: 500px) {
    .bigFalse {
        flex-basis: 100%;
    }
}