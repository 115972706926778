/*home.css is for home styles*/

.landing {
    /*background-image: url("../media/backsplash.jpg");*/
    background-color: rgba(0,0,0,0.3);
}

.projects {
    background-image: url("../media/home/PayloadBacksplash.jpg");
}

.outreach {
    background-image: url("../media/home/OutreachBacksplash.jpg");
}

.eventsContainer {
    display: flex;
    flex-wrap: wrap;
}

.RocketVideo {
    width: 400px;
    border-radius: 10px;
}

.backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}

.weare .row {
    margin-top: 35px;
    justify-content: space-between;
}

.infoNote {
    display: block;
    margin-top: 20px;
}

.ESRALogo {
    height: 35px;
}

.heroTextContainer {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
}

.heroTextContainer.visible {
    opacity: 1;
    transform: translateY(0);
}

@media screen and (max-width: 900px) {
    .RocketVideo {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .weare .row {
        flex-direction: column;
    }
}
